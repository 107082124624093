import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";
import {FaPen} from 'react-icons/fa';
import {FaCommentDots} from 'react-icons/fa';
import {FaVideo } from 'react-icons/fa';

function Contentcreationdetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2 flex flex-col items-center">
                                <div className="sx-head-s-title text-center text-2xl font-bold mt-4">
                                <h2>Content Creation</h2></div>
                                <div className="sx-head-l-title text-center">
                                    <h2 className="sx-title2">Engaging Content for Audience Growth</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                In a world driven by digital engagement, content is at the core of effective communication. Our content creation services are crafted to connect with your audience by producing high-quality, meaningful content that reflects your brand’s unique voice and vision. Whether you're looking to boost your content marketing efforts, create a compelling brand strategy, or engage your audience effectively, we are here to help you succeed. Let us create content that resonates and drives results for your business
                                </p>
                                <p>
                                    From blog posts and articles to social media content and multimedia, we offer a range of content solutions tailored to your objectives. Our team of writers, designers, and strategists works collaboratively to ensure each piece of content aligns with your brand’s goals and captures your target audience’s attention.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaPen style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Blog & Article Writing</span>
                                                <span className="sx-icon-bx-title-info">Informative and SEO-friendly content</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaCommentDots style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Social Media Content</span>
                                                <span className="sx-icon-bx-title-info">Engaging posts for all platforms</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaVideo  style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Multimedia Content</span>
                                                <span className="sx-icon-bx-title-info">Videos, graphics, and more</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                We combine creativity with data-driven insights to produce engaging content that resonates with your audience and drives customer engagement. Let us help you tell your brand story and establish a consistent, impactful presence across all digital platforms. 
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Contentcreationdetail;
