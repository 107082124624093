import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";
import {FaDesktop } from 'react-icons/fa';
import {FaPrint } from 'react-icons/fa';
import {FaPenFancy } from 'react-icons/fa';

function Graphicdesigndetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2 flex flex-col items-center">
                                <div className="sx-head-s-title text-center text-2xl font-bold mt-4">
                                <h2>Graphic Design</h2></div>
                                <div className="sx-head-l-title text-center">
                                    <h2 className="sx-title2">Elevating Your Brand Through Design</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                Visual appeal is crucial in making a memorable impression. Our graphic design services help brands communicate effectively and creatively, delivering high-quality designs that capture attention and embody your brand’s identity. From custom logos to brand identity development, we craft engaging visual content that enhances your brand’s aesthetic and credibility.
                                </p>
                                <p>
                                From custom logo design to complete branding packages, our team of creative designers is here to transform your ideas into stunning visuals that resonate with your audience. We specialize in a wide range of design solutions, including digital graphics, print materials, and custom illustrations, all tailored to fit your unique style and business goals.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaDesktop style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Logo & Branding</span>
                                                <span className="sx-icon-bx-title-info">Distinctive brand identity</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaPrint style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Print & Digital Design</span>
                                                <span className="sx-icon-bx-title-info">Brochures, flyers, and more</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaPenFancy style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Custom Illustrations</span>
                                                <span className="sx-icon-bx-title-info">Unique visual storytelling</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                Combining aesthetics with strategy, we create visually stunning designs that not only look great but also drive your business objectives. Our goal is to craft high-impact visuals that enhance your brand identity and ensure your message stands out across digital and print media.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Graphicdesigndetail;
