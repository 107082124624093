import React from 'react';
import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import Slider from "react-slick"; // Import Slick Slider

function SectionClients1() {
    // Slick slider settings
    const settings = {
        dots: false,
        infinite: true,
        speed:2500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 0.0000000000001, // Duration for each slide (in milliseconds)
        pauseOnHover: false, // Pause autoplay on hover
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                },
            },
        ],
    };

    // Client data with descriptions
    const clients = [
        { logo: "images/client-logo/white/3cits.jpg", name: "3CIT Solution", description: "IT Solutions and Services" },
        { logo: "images/client-logo/white/PMPML.jpg", name: "PMPML", description: "Description for Client 2" },
        { logo: "images/client-logo/white/divyangbhawan.jpg", name: "Divyang Bhavan", description: "Description for Divyang Bhavan" },
        { logo: "images/client-logo/white/sandhyanews.jpg", name: "Sandhya News", description: "Description for Sandhya News" },
        { logo: "images/client-logo/white/exerenergy.jpg", name: "Exer Energy", description: "Description for Client 3" },
        { logo: "images/client-logo/white/surprisecar.jpg", name: "Surprise Car", description: "Description for Client 4" },
        { logo: "images/client-logo/white/jeevandhara.jpg", name: "Jeevandhara Hospital", description: "Description for Client 5" },
        { logo: "images/client-logo/white/saraipreschool.jpg", name: "Sarai Pre School", description: "Description for Sarai Pre School" },
        { logo: "images/client-logo/white/saraisports.jpg", name: "Sarai Sport Academy", description: "Description for Client 13" },
        { logo: "images/client-logo/white/ganeshpeth.jpg", name: "Ganeshpeth", description: "Description for Ganeshpeth" },
        { logo: "images/client-logo/white/valentinepool.jpg", name: "Valentine", description: "Description for valentine" },
        { logo: "images/client-logo/white/nishtha.jpg", name: "Nishtha", description: "Description for Nishtha" },
        { logo: "images/client-logo/white/balajiresort.jpg", name: "Balaji Resort", description: "Description for Client 6" },
        { logo: "images/client-logo/white/smitdentalclinic.jpg", name: "Smit Dental", description: "Description for Client 11" },
        { logo: "images/client-logo/white/mishaka.jpg", name: "Mishkha EDTECH", description: "Description for Client 7" },
        { logo: "images/client-logo/white/thegreeneats.jpg", name: "The Green Eats", description: "Description for Client 8" },
        { logo: "images/client-logo/white/unclekitaxi.jpg", name: "Uncle Ki Tax", description: "Description for Client 9" },
        { logo: "images/client-logo/white/wizcorp.jpg", name: "Wizcrop", description: "Description for Client 10" },    
        { logo: "images/client-logo/white/themotocafe.jpg", name: "Moto cafe", description: "Description for Client 12" },
        { logo: "images/client-logo/white/darshita.jpg", name: "Entrenched", description: "Description for Entrenched" },
         { logo: "images/client-logo/white/anira.jpg", name: "Anira", description: "Description for Anira" },
        { logo: "images/client-logo/white/bhartihospital.jpg", name: "Bharati Hospital", description: "Description for Bharati Hospital" },
        { logo: "images/client-logo/white/brainwise.jpg", name: "Brainwise", description: "Description for Entrenched" },
        { logo: "images/client-logo/white/dr.gujrathi.jpg", name: "Dr.Gujrathi", description: "Description for Entrenched" },
        { logo: "images/client-logo/white/Lexcredence.jpg", name: "Lex Credence", description: "Description for Entrenched" },
        { logo: "images/client-logo/white/mahatmaphulekrishvidyapeeth.jpg", name: "MPKV", description: "Description for Entrenched" },
        { logo: "images/client-logo/white/santsopankaka.jpg", name: "Sant Sopankaka", description: "Description for Entrenched" },
        { logo: "images/client-logo/white/silverjubleemotors.jpg", name: "Silver Jublee", description: "Description for Entrenched" },
        { logo: "images/client-logo/white/tatahendrickson.jpg", name: "TATA Hendrickson", description: "Description for Entrenched" },
        
    ];

    return (
        <div className="section-full p-t110 p-b80 sx-client-logo-2-outer">
            <div className="container">
                <div className="section-content">
                    <div className="section-head center max-900">
                        <div className="sx-head-s-title">Our Clients</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title2">Our 120 happy Client around worldwide.</h2>
                        </div>
                    </div>
                    <div className="client-logo-pic2-wrap">
                        <Slider {...settings}>
                            {clients.map((client, index) => (
                                <div key={index} className='flex flex-col items-center'>
                                    <NavLink to="/contact-us" className="client-logo-pic2">
                                        <ItodoImage src={client.logo} alt={client.name} />
                                    </NavLink>
                                    <h6 className="text-center font-bold text-sm mt-2">{client.name}</h6>
                                    {/* <p className="text-center text-sm text-gray-800">{client.description}</p> */}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionClients1;