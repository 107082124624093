import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "./section-testimonials2";

export function SectionWhyChooseUs2Inner() {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/about/p3.png" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title">Join Us for Growth, Innovation, and Success!</h2>
                        <p>At Azown Technology, we drive innovation, digital transformation, and business growth. Our expert team thrives on creativity and cutting-edge technologies to deliver top-notch digital solutions. Join us to collaborate with industry leaders, enhance your skills, and make a meaningful impact. Together, we create opportunities, achieve success, and build a future of excellence.</p>
                        <ul className="sx-arrow-circle-checked">
                            <li>One-time security check </li>
                            <li>Elite membership </li>
                            <li>Coast-to-coast domestic flights</li>
                        </ul>
                        <NavLink to="/services" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa  fa-long-arrow-right" />
                            View All
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionWhyChooseUs2() {
    return (
        <>
            <div className="section-full  p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUs2Inner />
                        </div>
                        {/*testimonial section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs2;