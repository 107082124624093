import React from 'react'
 import Banner from "../../../sections/common/banner";
import { bannerData } from "../../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";
import SectionServices1 from "../../../sections/home/index/section-services1";

import SectionClients1 from '../../../sections/home/index/section-clients1';

import Socialmediadetail from '../servicesdetail/Socialmediadetail';



function Socialmedia () {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
  return (
    <>
             <Banner _data={bannerData.services} /> 
            
            <Socialmediadetail />    
            
            <SectionClients1/>
            <SectionServices1/>
            
            
            
            
    </>
  )
}

export default Socialmedia ;