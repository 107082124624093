import ItodoImage from "../../../elements/itodo-img";
import { publicUrlFor } from "../../../../globals/constants";
import ReactPlayer from 'react-player/youtube';
import { useState } from 'react'; // Import useState

//import SectionCounter1 from "./section-counter1";
//import ContactForm from "../../../components/contactus/ContactForm";

function SectionAboutCompany1() {

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
      const [videoUrl, setVideoUrl] = useState(""); // State to dynamically handle the video URL
  
      // Open modal and set the video URL
      const openModal = (url) => {
          setVideoUrl(url);
          setIsModalOpen(true);
      };
  
      // Close modal
      const closeModal = () => {
          setIsModalOpen(false);
          setVideoUrl("");
      };
  return (
    <>
      <div className="section-full  p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-bx1-content-wrap">
              <div className="row">
                <div
                  className="col-lg-6 col-md-12 wow fadeInDown"
                  data-wow-duration="1000ms"
                >
                  <div className="sx-about-bx1-media">
                    <div className="sx-dot-bx">
                      <ItodoImage src="images/about/dotted-pic.png" alt="" />
                    </div>
                    <div className="sx-ab-img-outer">
                      <div className="sx-ab-img-section-1-wrap">
                        <div className="sx-ab-video">
                          
                        <button
                                    className="play-button circle"
                                    onClick={() => openModal("https://youtube.com/shorts/-5vg1qaIO8o")}
                                >
                                    <i className="flaticon-play" />
                                </button>
                        </div>
                        <div
                          className="sx-ab-img-section-1"
                          style={{
                            backgroundImage: `url(${publicUrlFor(
                              "images/about/Azown-Technology.png"
                            )})`,
                          }}
                        />
                      </div>
                      
                      <div className="sx-ab-img-section-2">
                        <ItodoImage src="images/about/p1.png" alt="About Azown" />
                        
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-12 wow fadeInDown"
                  data-wow-duration="1000ms"
                >
                  <div className="sx-about-bx1-content">
                    <h1 className="sx-title">
                      Best Digital Marketing Services In Pune
                    </h1>
                    <span className="sx-title-2">
                      Welcome to Azown Technology
                    </span>
                    <h2 className="sx-title-3">
                      Azown Technology A Leading Digital Marketing Agency in Pune,
                      Committed to Helping Businesses Enhance Their Online Presence and Achieve Measurable Success.
                    </h2>
                    <p>
                      Our mission is to provide tailored, results-driven digital solutions that align with your business objectives.
                      With a dedicated team of experts in website development, UI/UX design, SEO,
                     SEM, social media marketing,  content creation, and paid advertising,
                      we help your brand stand out in the competitive digital landscape.
                      <br/><br/> From Conceptualization to Execution, Azown Technology Offers Comprehensive Digital Marketing Solutions 
                      for Businesses of All Sizes. We Combine Creativity and Technology to Achieve Exceptional Results.
                      <br/><br/> Whether You Need a Responsive Website, Engaging Social Media Campaigns,
                       or Enhanced Search Engine Visibility, Our Innovative Digital Marketing Strategies
                       Deliver ROI-Driven Growth. Partner with Azown Technology to Experience the Power of Digital Transformation!
                    </p>
                    <div className="progress">
                      <div
                        className="progress-bar sx-bg-primary"
                        role="progressbar"
                        style={{ width: "75%" }}
                        aria-valuenow={75}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span>95.00% Project success </span>
                      </div>
                    </div>
                    <div className="row sx-about-bx1-column">
                      <div className="col-lg-6 col-md-6">
                        <p>Effective project execution</p>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <p>Trustworthy Communication.</p>
                      </div>
                    </div>
                    <div className="row sx-about-icon-bx1-column">
                      <div className="col-lg-6 col-md-6">
                        <div className="sx-icon-bx-2">
                          <div className="sx-media">
                            <i className="flaticon-mouse" />
                          </div>
                          <div className="sx-icon-bx-info">
                            <span className="sx-icon-bx-title">
                              Digital Marketing
                            </span>
                            {/* <span className="sx-icon-bx-title-info">02 Project</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="sx-icon-bx-2">
                          <div className="sx-media">
                            <i className="flaticon-download" />
                          </div>
                          <div className="sx-icon-bx-info">
                            <span className="sx-icon-bx-title">
                              IT Solution
                            </span>
                            {/* <span className="sx-icon-bx-title-info">04 Project</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* COUNTER START */}
            
            {/* COUNTER  END */}
          </div>
        </div>
        {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <button className="close-button" onClick={closeModal}>
                                    ×
                                </button>
                                <ReactPlayer
                                    url={videoUrl}
                                    playing
                                    controls
                                    width="70%"
                                    height="70%"
                                />
                            </div>
                        </div>
                    )}
      </div>
    </>
  );
}

export default SectionAboutCompany1;
