import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faYoutube, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';


const SocialMediaPosts = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    // Check if window object is available to avoid SSR issues
    if (typeof window !== "undefined") {
      setIsDesktop(window.innerWidth > 768);

      const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
      };

      window.addEventListener("resize", handleResize);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const Logo = "/logo192.png";

  const containerStyle = {
    display: "flex",
    flexDirection: isDesktop ? "row" : "column",
    justifyContent: isDesktop ? "space-between" : "center",
    alignItems: "flex-start",
    padding: "20px",
    gap: "20px",
  };

  

  const sectionStyle = {
    width: isDesktop ? "30%" : "90%",
    maxWidth: "400px",
    margin: isDesktop ? "0" : "10px auto",
    
  };

  const headerStyle = {
    textAlign: "center",
    color: "Black",
    marginBottom: "10px",
  };

  const postCardStyle = {
    overflow: "hidden",
  };

  const iframeStyle = {
    border: "none",
    overflow: "hidden",
    width: "100%",
    height: isDesktop ? "500px" : "400px",
  };

  const circleContainerStyle = {
    position: "relative",
    width: "200px",
    height: "200px",
    margin: "70px auto",
    border: "2px dotted #ff4d4d",
    borderRadius: "50%",
    animation: "rotate 10s linear infinite", // This will apply the 360-degree rotation
  };

  const iconStyle = {
    position: "absolute",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontSize: "20px",
    color: "#ff4d4d",
  };

  

  const positions = [
    { top: "-5%", left: "17%", transform: "translate(-50%, -50%)" }, // Top
    { top: "20%", left: "87%", transform: "translate(-50%, -50%)" }, // Top-right
    { top: "80%", left: "68%", transform: "translate(-50%, -50%)" }, // Bottom-right
    { top: "55%", left: "-7%", transform: "translate(-50%, -50%)" }, // Bottom
    { top: "50%", left: "-10%", transform: "translate(-50%, -50%)" }, // Bottom-left
    { top: "25%", left: "15%", transform: "translate(-50%, -50%)" }, // Top-left
  ];

  const socialIcons = [
    { name: "Facebook", color: "#4267B2", icon: faFacebookF, link: "https://www.facebook.com/profile.php?id=100091387818263&mibextid=ZbWKwL" },
    { name: "LinkedIn", color: "#0077B5", icon: faLinkedinIn, link: "https://www.linkedin.com/company/azown-technologies/" },
    { name: "YouTube", color: "#FF0000", icon: faYoutube, link: "https://www.youtube.com/@azowntechnology" },
    // { name: "Twitter", color: "#1DA1F2", icon: faTwitter, link: "https://x.com/PurpleJallosh" },
    { name: "Instagram", color: "#C13584", icon: faInstagram, link: "https://www.instagram.com/azown_technology?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" },
  ];

  // Adding the CSS for rotation (animation)
  const styles = `
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
  
  // Include the above CSS in your document head or via styled-components
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(styles, styleSheet.cssRules.length);

  return (
    <div style={containerStyle}>
      {/* Left Side: Facebook Section */}
      <div style={sectionStyle}>
        <h2 style={headerStyle}>Facebook</h2>
        <div style={postCardStyle}>
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100091387818263%26mibextid%3DZbWKwL&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            style={iframeStyle}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>

      {/* Center: Circular Social Media Icons */}
      <div style={sectionStyle}>
        <h2 style={headerStyle}>Social Media Handle</h2>
        <div style={circleContainerStyle}>
          {socialIcons.map((icon, index) => (
            <a
              key={index}
              href={icon.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                ...positions[index],
                position: "absolute",
              }}
            >
              <div
                style={{
                  ...iconStyle,
                  color: icon.color,
                }}
              >
                {/* Using FontAwesome Icon */}
                <FontAwesomeIcon icon={icon.icon} size="1.8x" />
              </div>
            </a>
          ))}
        </div>
      </div>

      {/* Right Side: Instagram Section */}
      <div style={sectionStyle}>
        <h2 style={headerStyle}>Instagram</h2>
        <div style={postCardStyle}>
          <iframe
            src="https://www.instagram.com/azown_technology/embed"
            style={iframeStyle}
            scrolling="no"
            allowFullScreen={true}
            allow="encrypted-media"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaPosts;
