import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";
import {FaNetworkWired} from 'react-icons/fa';
import {FaPrint} from 'react-icons/fa';
import {FaPenFancy} from 'react-icons/fa';

function Socialmediadetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer bg-white">
                <div className="container mx-auto">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2 flex flex-col items-center">
                                <div className="sx-head-s-title text-center text-2xl font-bold mt-4"><h2>Social Media Services</h2></div>
                                <div className="sx-head-l-title text-center">
                                    <h2 className="sx-title2 text-4xl font-semibold mt-4">Empowering Your Brand Through Social Media Marketing</h2>
                                    <hr className="border border-gray-300 my-4 w-1/2" />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info mb-20">
                                <p className="text-lg mb-4">
                                Social media has become an essential platform for businesses to connect with their audience, build brand awareness, and drive engagement. Our social media services are designed to help you maximize your online presence and achieve measurable results. We offer social media strategy, content creation, community management, and analytics-driven solutions to ensure your brand stands out across platforms.
                                </p>
                                <p className="text-lg mb-4">
                                From crafting compelling content to managing social media campaigns across platforms, our team specializes in delivering tailored social media strategies that align with your business objectives. We focus on creating meaningful interactions that resonate with your audience, driving engagement and fostering long-term brand loyalty.
                                </p>
                                <div className="row sx-about-icon-bx1-column pt-12 pb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                    <div className="col-lg-4 col-md-6 mb-6">
                                        <div className="sx-icon-bx-2 text-center">
                                            <div className="sx-media mb-2">
                                            <FaNetworkWired style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title font-semibold">Social Media Strategy</span>
                                                <span className="sx-icon-bx-title-info text-gray-600">Customized Social Media Plans for Business Growth</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-6">
                                        <div className="sx-icon-bx-2 text-center">
                                            <div className="sx-media mb-2">
                                            <FaPrint style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title font-semibold">Print & Digital Design</span>
                                                <span className="sx-icon-bx-title-info text-gray-600">Brochures, flyers, and more</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-6">
                                        <div className="sx-icon-bx-2 text-center">
                                            <div className="sx-media mb-2">
                                            <FaPenFancy style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title font-semibold">Custom Illustrations</span>
                                                <span className="sx-icon-bx-title-info text-gray-600">Unique visual storytelling</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-lg mb-4">
                                Our social media services cover everything from account setup and audience targeting to campaign management and performance tracking. Whether it’s building your brand’s identity, driving conversions, or increasing engagement, we’re here to ensure your social media efforts deliver tangible results.
                                </p>
                                <p className="text-lg mb-4">
                                We understand that every business is unique, which is why we take the time to understand your specific needs and goals. Our team stays up-to-date with the latest social media marketing trends and best practices to ensure that your brand remains relevant and competitive in the ever-evolving digital landscape.
                                </p>
                                <p className="text-lg mb-4">
                                Let us help you navigate the complexities of social media marketing. With our expertise, we create engaging content that not only captures attention but also drives action. Our analytics-driven approach allows us to measure the success of our strategies and make data-informed adjustments to optimize your social media campaigns for better performance.
                                </p>
                                <p className="text-lg mb-4">
                                Whether you are looking to increase your followers, enhance engagement, or drive traffic to your website, our comprehensive social media services are designed to meet your needs. We specialize in creating customized social media strategies that boost your online presence, engage your target audience, and drive measurable results.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Socialmediadetail;