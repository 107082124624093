import { publicUrlFor } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { register } from 'swiper/element/bundle';
import ReactPlayer from 'react-player/youtube';
import { useState } from 'react'; // Import useState


register();

function SectionSlider1() {
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [videoUrl, setVideoUrl] = useState(""); // State to dynamically handle the video URL

    // Open modal and set the video URL
    const openModal = (url) => {
        setVideoUrl(url);
        setIsModalOpen(true);
    };

    // Close modal
    const closeModal = () => {
        setIsModalOpen(false);
        setVideoUrl("");
    };
    return (
        <>
            <div className="sx-bnr-1-wrap-outer home-1-slider">
                {/* swiper slides */}
                <swiper-container
                    loop="true"
                    space-between="30"
                    effect="fade"
                    navigation-next-el=".swiper-button-next"
                    navigation-perv-el=".swiper-button-prev"
                    pagination-el=".swiper-pagination"
                    pagination-clickable="true"
                    autoplay-delay="3000"
                    autoplay-disable-on-interaction="true"
                    class="swiper-wrapper"
                >
                    <swiper-slide class="sx-bnr-1-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider1/s1.jpg")})` }}>
                        <div className="sx-bg-overlay  sx-bg-primary opacity-0" />
                        <div className="container">
                            <div className="sx-bnr-1-content">
                                <span className="sx-bnr-1-small-title" data-swiper-parallax="-300">We are Dedicated</span>
                                <h2 className="sx-bnr-1-large-title">inspiring and passionate about innovative.</h2>
                                {/* <div className="sx-bnr-1-info">Fusce lacinia elit odio, id pulvinar erat placerat vitae. Sed quis aliquet neque. Aenean placerat condimentum lorem non suscipit. Cras tellus tellus, fringilla eu sapien.</div> */}
                                <div className="sx-bnr-readmore">
                                    <NavLink to="/about-us" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        Read More
                                    </NavLink>
                                </div>
                                <div className="sx-bnr-video">
                                <button
                                    className="play-button circle"
                                    onClick={() => openModal("https://youtube.com/shorts/-5vg1qaIO8o")}
                                >
                                    <i className="flaticon-play" />
                                </button>
                            </div>
                            </div>
                        </div>
                        {/* <div className="sx-bnr-text-masking">
                            <div className="light">
                                <h1 className="animate-charcter">IT Solution</h1>
                            </div>
                        </div> */}
                    </swiper-slide>
                    <swiper-slide class="sx-bnr-1-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider1/s2.jpg")})` }}>
                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-1-content">
                                <span className="sx-bnr-1-small-title" data-swiper-parallax="-300">We are The best</span>
                                <h2 className="sx-bnr-1-large-title">We are ready to deliver expert IT solutions.</h2>
                                {/* <div className="sx-bnr-1-info">Fusce lacinia elit odio, id pulvinar erat placerat vitae. Sed quis aliquet neque. Aenean placerat condimentum lorem non suscipit. Cras tellus tellus, fringilla eu sapien.</div> */}
                                <div className="sx-bnr-readmore">
                                    <NavLink to="/about-us" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        Read More
                                    </NavLink>
                                </div>
                                <div className="sx-bnr-video">
                                <button
                                    className="play-button circle"
                                    onClick={() => openModal("https://youtube.com/shorts/-5vg1qaIO8o")}
                                >
                                    <i className="flaticon-play" />
                                </button>
                            </div>
                            </div>
                        </div>
                        {/* <div className="sx-bnr-text-masking">
                            <div className="light">
                                <h1 className="sx-title">Analytics</h1>
                            </div>
                        </div> */}
                    </swiper-slide>
                </swiper-container>
                {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="close-button" onClick={closeModal}>
                            ×
                        </button>
                        <ReactPlayer
                            url={videoUrl}
                            playing
                            controls
                            width="70%"
                            height="70%"
                        />
                    </div>
                </div>
            )}
                {/* !swiper slides */}
                {/* Add Arrows */}
                {/* <div className="swiper-button-next" />
                <div className="swiper-button-prev" /> */}
            </div>
        </>
    )
}

export default SectionSlider1;