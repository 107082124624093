import React from "react";
//import Banner from "../../sections/common/banner";
import { Link } from "react-router-dom";
//import { bannerData } from "../../../globals/banner";
import service1 from "../../../images/ser-1.jpg";
import service2 from "../../../images/ser-2.jpg";
import service3 from "../../../images/ser-3.jpg";
import service4 from "../../../images/ser-4.jpg";
import service5 from "../../../images/ser-5.jpg";
import service6 from "../../../images/ser-6.jpg";
import service7 from "../../../images/ser-7.jpg";
import service8 from "../../../images/ser-8.jpg";
import service9 from "../../../images/ser-9.jpg";
import service10 from "../../../images/ser-10.jpg";
import service11 from "../../../images/ser-11.jpg";
import service12 from "../../../images/ser-16.jpg";
import service13 from "../../../images/ser-13.jpg";
import service14 from "../../../images/ser-14.jpg";
import service15 from "../../../images/ser-15.jpg";
import service16 from "../../../images/ser-11.jpg";
import service17 from "../../../images/ser-17.jpg";
import service18 from "../../../images/ser-18.jpg";




import "./portfolio.css";
const Portfolio1 = () => {
  const projects = [
    {
      id: 1,
      title: "PCMC's Purple Jallosh Event- 2025",
      service:"Digital Marketing",
      image: service1, // Replace with actual image URL
      link: "#", // Route for this project
    },
    
    {
      id: 2,
      title: "PCMC Divyang Bhavan Foundation ",
      service:"Digital Marketing",
      image: service2, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 3,
      title: "PMPML",
      service:"Digital Marketing",
      image: service3, // Replace with actual image URL
      link: "#", // Route for this project
    },
    
    
    {
      id: 4,
      title: "Sarai kids",
      service:"Digital Marketing",
      image: service4, // Replace with actual image URL
      link: "#", // Route for this project
    },
    
    {
      id: 5,
      title: "Smit Dental Clinic",
      service:"Digital Marketing",
      image: service5, // Replace with actual image URL
      link: "#", // Route for this project
    },
    
    {
      id: 6,
      title: " Surprise Cars",
      service:"Digital Marketing",
      image: service6, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 7,
      title: " Ganesh Peth",
      service:"Digital Marketing",
      image: service7, // Replace with actual image URL
      link: "#", // Route for this project
    },
    
    {
      id: 8,
      title: " Sandhya News",
      service:"Digital Marketing",
      image: service8, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 9,
      title: " Anira Farm",
      service:"Digital Marketing",
      image: service9, // Replace with actual image URL
      link: "#", // Route for this project
    },
    
     {
       id: 10,
       title: " Lex Credence",
       service:"Digital Marketing",
       image: service10, // Replace with actual image URL
       link: "#", // Route for this project
     },
    
    {
      id: 11,
      title: " Valetine Pool Snooker Club",
      service:"Digital Marketing",
      image: service11, // Replace with actual image URL
      link: "#", // Route for this project
    },
     {
      id: 12,
      title: " Exer Enegry",
       service:"Digital Marketing",
       image: service12, // Replace with actual image URL
      link: "#", // Route for this project
    },
     {
       id: 13,
       title: " Brainwise",
       service:"Digital Marketing",
       image: service13, // Replace with actual image URL
       link: "#", // Route for this project
     },
    
    
    {
      id: 14,
      title: " Bharati Hospital",
      service:"Digital Marketing",
      image: service14, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 15,
      title: " Jeevandhara Hospital",
      service:"Digital Marketing",
      image: service15, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 16,
      title: " Moto Cafe",
      service:"Digital Marketing",
      image: service16, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 17,
      title: " Darshita",
      service:"Digital Marketing",
      image: service17, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 18,
      title: " Politics",
      service:"Digital Marketing",
      image: service18, // Replace with actual image URL
      link: "#", // Route for this project
    },
    
  ];

  return (
    <div>
      
      <h2 className="section-heading"style={{ textAlign: "center",padding:"10px",
      fontSize:"34px",backgroundColor: "black", color: "white",
      border: "2px solid white",borderRadius: "8px" }}
      >DIGITAL MARKETING</h2> 
      <div className="portfolio-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-card">
                <Link to={project.link} key={project.id} className="project-card">
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="title-p">
            <h3 className="project-title" id="title-p1">{project.title}</h3>
            <p className="project-title1">{project.service}</p>
            </div>
          </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio1;
