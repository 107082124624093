import React from "react";
import Banner from "../../sections/common/banner";
import { Link } from "react-router-dom";
import { bannerData } from "../../../globals/banner";
import Portfolio1 from "../company/Portfolio1";
import service1 from "../../../images/servi-1.jpg";
import service2 from "../../../images/servi-2.jpg";
import service3 from "../../../images/servi-3.jpg";
import service4 from "../../../images/servi-4.jpg";
import service5 from "../../../images/servi-5.jpg";
import service6 from "../../../images/servi-6.jpg";
import service7 from "../../../images/servi-7.jpg";
import service8 from "../../../images/servi-8.jpg";
import service9 from "../../../images/servi-9.jpg";
//import service10 from "../../../images/servi-10.jpg";
//import service11 from "../../../images/servi-11.jpg";
import service12 from "../../../images/servi-12.jpg";
//import service13 from "../../../images/servi-13.jpg";
import service14 from "../../../images/servi-14.jpg";
import service15 from "../../../images/servi-15.jpg";



import "./portfolio.css";
const Portfolio = () => {
  const projects = [
    {
      id: 1,
      title: "PCMC's Purple Jallosh Event- 2025",
      service:"Website Development",
      image: service1, // Replace with actual image URL
      link: "https://purplejallosh.pcmcdivyangbhavan.org/", // Route for this project
    },
    {
      id: 2,
      title: "PCMC Divyang Bhavan Foundation",
      service:"Website Development",
      image: service2, // Replace with actual image URL
      link: "https://pcmcdivyangbhavan.org/", // Route for this project
    },
    
    {
      id: 3,
      title: "DNTL Compliance",
      service:"Website Development",
      image: service3, // Replace with actual image URL
      link: "https://dntl.co.in/compliance/", // Route for this project
    },
    {
      id: 4,
      title: "3cit Solutions",
      service:"Website Development",
      image: service4, // Replace with actual image URL
      link: "https://3citsolutions.com/", // Route for this project
    },
    {
      id: 5,
      title: "Nishtha Essentials",
      service:"Website development",
      image: service5, // Replace with actual image URL
      link: "https://www.nishthaessentials.com/", // Route for this project
    },
    {
      id: 6,
      title: "Jeevandhara Hospital",
      service:"Website Development",
      image: service6, // Replace with actual image URL
      link: "https://jeevandhaarahospital.com/", // Route for this project
    },
    {
      id: 7,
      title: "Uncle Ki Taxi",
      service:"Website Development",
      image: service7,// Replace with actual image URL
      link: "https://unclekitaxi.com/", // Route for this project
    },
    {
      id: 8,
      title: " Balaji Resort",
      service:"Website Development",
      image: service8, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 9,
      title: " Dr.Gujrathi-Homeopathy",
      service:"Website Development",
      image: service9, // Replace with actual image URL
      link: "https://drgujarathi.com/", // Route for this project
    },
    // {
    //   id: 10,
    //   title: " Mishka EDTECH",
    //   service:"Website Development",
    //   image: service10,// Replace with actual image URL
    //   link: "#", // Route for this project
    // },
    // {
    //   id: 11,
    //   title: " Silver Jublee",
    //   service:"Website Development",
    //   image: service11,// Replace with actual image URL
    //   link: "#", // Route for this project
    // },
    {
      id: 12,
      title: " Sant Sopankaka Sahakari Bank",
      service:"Website Development",
      image: service12,// Replace with actual image URL
      link: "https://www.santsopankakabank.com", // Route for this project
    },
    // {
    //   id: 13,
    //   title: " Wizcrop",
    //   service:"Website Development",
    //   image: service13,// Replace with actual image URL
    //   link: "#", // Route for this project
    // },
    {
      id: 14,
      title: " AKSDC Bharati Vidyapeeth",
      service:"Website Development",
      image: service14,// Replace with actual image URL
      link: "https://aksdc.bharatividyapeeth.edu/", // Route for this project
    },
    {
      id: 15,
      title: "Exer Energy",
      service:"Website Development",
      image: service15,// Replace with actual image URL
      link: "https://www.exerenergy.com/", // Route for this project
    },

    
  ];

  return (
    
    <div>
      <Banner _data={bannerData.mission} />
       <h2 className="section-heading"style={{ textAlign: "center",padding:"10px",
      fontSize:"34px",backgroundColor: "black", color: "white",
      border: "2px solid white",borderRadius: "8px" }}
      >WEBSITE DEVELOPMENT</h2> 
      <div className="portfolio-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-card">
                <Link to={project.link} key={project.id} className="project-card">
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="title-p">
            <h3 className="project-title" id="title-p1">{project.title}</h3>
            <p className="project-title1">{project.service}</p>
            </div>
          </Link>
          </div>
        ))}
      </div>
      <Portfolio1/>
    </div>
   
    
  );
};

export default Portfolio;
