import { bannerData } from "../../../globals/banner";
import React, { useRef } from 'react';
import Banner from "../../sections/common/banner";
import emailjs from '@emailjs/browser';


function ContactUsPage() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_bwcnp0u', 'template_bm3culp', form.current, 'omfVXMBY3m-RW0Oo8') // Corrected the parameters
            .then(
                () => {
                    alert('Message sent successfully!'); // Notify user of success
                    form.current.reset(); // Reset the form after successful submission
                },
                (error) => {
                    console.error('FAILED...', error.text);
                    alert('Failed to send message. Please try again later.'); // Notify user of failure
                }
            );
    };

    return (
        <>
            <Banner _data={bannerData.contact} />

            {/* Contact us START */}
            <div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer ">
                <div className="container">
                    {/* GOOGLE MAP & CONTACT FORM */}
                    <div className="section-content">
                        {/* CONTACT FORM*/}
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8  m-b30">
                                <div className="contact-info">
                                    {/* TITLE START */}
                                    <div className="section-head left">
                                        <div className="sx-head-s-title">Contact Us</div>
                                        <div className="sx-head-l-title">
                                            <h2 className="sx-title">Our creative design solutions are for people, Contact Our Office. </h2>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="row sx-contact-form-icon-bx">
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-telephone" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="/#" className="sx-icon-bx-title-info">Contact Phone</a>
                                                    <a href="/#" className="sx-icon-bx-title">+91 9309534220</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-email-2" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="/#" className="sx-icon-bx-title-info">Contact Mail</a>
                                                    <a href="/#" className="sx-icon-bx-title">azown1@gmail.com</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-map-location" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="/#" className="sx-icon-bx-title-info">Contact Location</a>
                                                    <a href="/#" className="sx-icon-bx-title">201, C-Wing Alacrity, behind Bitwise, mohan nagar, Baner, Pune, Maharashtra 411046</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-clock" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="/#" className="sx-icon-bx-title-info">Office Time</a>
                                                    <a href="/#" className="sx-icon-bx-title">
                                                        Mon- Sat: 10:00 AM - 07:00 PM
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 m-b30">
                                <div className="contact-home1-right">
                                    <div className="contact-home-1-form ">
                                        <h4 className="sx-title text-center">Leave Your Message</h4>
                                        <form className="cons-contact-form2 form-transparent" ref={form} onSubmit={sendEmail}>
                                            <div className="input input-animate">
                                                <input type="text" name="username" id="name" required placeholder="Your Name" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="email" name="email" id="email" required placeholder="Your Email" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="text" name="phone" id="Phone" required placeholder="Phone Number" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input textarea input-animate">
                                                <textarea name="message" id="message" required placeholder="Your Message" defaultValue={""} />
                                                <span className="spin" />
                                            </div>
                                            <div className="sx-btn-center text-center p-t10">
                                                <button type="submit" className="site-button sx-btn-primary icon sx-btn-lg">
                                                    <i className="fa fa-long-arrow-right" />
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact us END */}
            <div className="gmap-outline">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.3907799716353!2d73.76532817595718!3d18.556412868076922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1658be81e9b%3A0x180db6e1be8acac0!2sAzown%20Technology%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1729867729406!5m2!1sen!2sin"
                    style={{ border: 0 }} allowFullScreen loading="lazy" title="Azown Map" />
            </div>
            
        </>
    )
}

export default ContactUsPage;